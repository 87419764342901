import { Box, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import Countdown from "react-countdown";
import { setCounterOver } from "../Auth/reduxSlice";
import { useDispatch } from "react-redux";

export default function CustomCountdown() {
  const dispatch = useDispatch()
  const storedTargetTime = window.sessionStorage.getItem("targetTime");
  const [targetTime, setTargetTime] = useState(storedTargetTime || null);

  useEffect(() => {
    if (!storedTargetTime) {
      const today = new Date(2023,10,1,8,0);
      // const today = new Date(2023,10,1,10,0);

      const targetTimestamp = today.getTime();
      window.sessionStorage.setItem("targetTime", targetTimestamp);
      setTargetTime(targetTimestamp);
    }
  }, [storedTargetTime]);

  const renderer = ({ days, hours, minutes, seconds, completed }) => {
    if (completed) {
      dispatch(setCounterOver({value:true}))
      // return (
      //   <Typography variant="h6" component="h4" className="text-center">
      //     Event has started, join now !!
      //   </Typography>
      // );
    } else {
      const formattedDays = String(days).padStart(2, "0");
      const formattedHours = String(hours).padStart(2, "0");
      const formattedMinutes = String(minutes).padStart(2, "0");
      const formattedSeconds = String(seconds).padStart(2, "0");
      return (
        <Box sx={{ userSelect: "none" }}>
          <Typography
            variant="h6"
            component="h4"
            className="text-center"
            sx={{ fontWeight: 200, mb: 1 }}
          >
            Event starts in
          </Typography>
          <Box className="d-flex gap-2">
            <Box className="text-center">
              <Typography
                variant="h4"
                component="div"
                sx={{
                  width: 70,
                  bgcolor: "#eee",
                }}
                className="p-3 text-dark rounded"
              >
                {formattedDays}
              </Typography>
              <Typography variant="subtitle1" component="h4">
                Days
              </Typography>
            </Box>
            <Box className="text-center">
              <Typography
                variant="h4"
                component="div"
                sx={{
                  width: 70,
                  bgcolor: "#eee",
                }}
                className="p-3 text-dark rounded"
              >
                {formattedHours}
              </Typography>
              <Typography variant="subtitle1" component="h4">
                Hours
              </Typography>
            </Box>
            <Box className="text-center">
              <Typography
                variant="h4"
                component="div"
                sx={{
                  width: 70,
                  bgcolor: "#eee",
                }}
                className="p-3 text-dark rounded"
              >
                {formattedMinutes}
              </Typography>
              <Typography variant="subtitle1" component="h4">
                Minutes
              </Typography>
            </Box>
            <Box className="text-center">
              <Typography
                variant="h4"
                component="div"
                sx={{
                  width: 70,
                  bgcolor: "#eee",
                }}
                className="p-3 text-dark rounded"
              >
                {formattedSeconds}
              </Typography>
              <Typography variant="subtitle1" component="h4">
                Seconds
              </Typography>
            </Box>
          </Box>
        </Box>
      );
    }
  };

  return targetTime ? (
    <Countdown date={Number(targetTime)} renderer={renderer} />
  ) : null;
}
