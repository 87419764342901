import React from "react";
import { Box, Divider, Grid, IconButton } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import FileDownloadOutlinedIcon from "@mui/icons-material/FileDownloadOutlined";
import { useDispatch, useSelector } from "react-redux";
import { authState, setModal } from "../../Auth/reduxSlice";
import { Button, Modal, ModalClose, Sheet } from "@mui/joy";

export default function CustomModal() {
  const dispatch = useDispatch();
  const { modal } = useSelector(authState);

  const handleClose = () =>
    dispatch(
      setModal({
        modal: {
          state: false,
          imgSrc: "",
          video: "",
        },
      })
    );

  return (
    <Modal
      aria-labelledby="modal-title"
      aria-describedby="modal-desc"
      open={modal.state}
      onClose={handleClose}
      sx={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <Sheet
        variant="outlined"
        sx={{
          width: "max-content",
          maxWidth: "60%",
          borderRadius: "md",
          p: 3,
          boxShadow: "lg",
        }}
      >
        <ModalClose
          variant="outlined"
          sx={{
            top: "calc(-1/4 * var(--IconButton-size))",
            right: "calc(-1/4 * var(--IconButton-size))",
            boxShadow: "0 2px 12px 0 rgba(0 0 0 / 0.2)",
            borderRadius: "50%",
            bgcolor: "background.surface",
          }}
        />
        <Grid container spacing={1}>
          {/* <Grid item xs={12} sx={{ display: "flex" }}>
            <Button
              size="sm"
              variant="plain"
              sx={{ textTransform: "capitalize" }}
              startDecorator={<FileDownloadOutlinedIcon />}
              download={
                modal.imageSrc
                  ? modal.imgSrc
                  : modal.videoSrc
                  ? modal.videoSrc
                  : null
              }
            >
              Download
            </Button>
          </Grid> */}
          {/* <Grid item xs={12}>
            <Divider />
          </Grid> */}
          <Grid item xs={12}>
            <Box sx={{ width: "100%", objectFit: "contain" }}>
              {modal.imageSrc && (
                <img src={modal.imageSrc} alt="..." style={{ width: "100%" }} />
              )}
              {modal.videoSrc && (
                <Box
                  sx={{
                    width: "100%",
                    minHeight:400,
                    aspectRatio: "16/9",
                  }}
                >
                  <iframe
                    width="100%"
                    height="100%"
                    src={modal.videoSrc}
                    title="Embedded Video"
                    allowFullScreen
                  />
                </Box>
              )}
            </Box>
          </Grid>
        </Grid>
      </Sheet>
    </Modal>
  );
}
