import React from "react";
import BlinkButton from "./components/BlinkButton";
import { Box, Button, IconButton, Typography } from "@mui/material";
import EditIcon from "@mui/icons-material/Edit";
import {
  FormControl,
  FormLabel,
  Input,
  Modal,
  ModalClose,
  Option,
  Select,
  Sheet,
} from "@mui/joy";
import { useForm } from "react-hook-form";
import { adminState } from "../AdminRole/reduxSlice";
import { useSelector } from "react-redux";

const data = [
  // {
  //   id: 1,
  //   img: "https://s3.ap-south-1.amazonaws.com/img.nularksolutions.com/Hubplus-Afrisummit/images/sampleImg.jpg",
  //   pdf: "",
  //   videoSrc: "",
  //   route: "",
  //   href:"",
  //   blinkPosition: { left: "17.2vw", top: "30.95vh" },
  //   editPosition: { left: "20vw", top: "0vh" },
  // },
  // {
  //   id: 2,
  //   img: "https://s3.ap-south-1.amazonaws.com/img.nularksolutions.com/Hubplus-Afrisummit/images/sampleImg1.jpg",
  //   pdf: "",
  //   videoSrc: "",
  //   route: "",
  //   href:"",
  //   blinkPosition: { left: "31.3vw", top: "33.95vh" },
  //   editPosition: { left: "34vw", top: "3.45vh" },
  // },
  // {
  //   id: 3,
  //   img: "",
  //   pdf: "https://s3.ap-south-1.amazonaws.com/img.nularksolutions.com/Hubplus-Afrisummit/images/Sample-pdf.pdf",
  //   videoSrc: "",
  //   route: "",
  //   href:"",
  //   blinkPosition: { left: "68.5vw", top: "33.95vh" },
  //   editPosition: { left: "70.8vw", top: "3.45vh" },
  // },
  // {
  //   id: 4,
  //   img: "",
  //   pdf: "",
  //   videoSrc:
  //     "https://s3.ap-south-1.amazonaws.com/img.nularksolutions.com/Hubplus-Afrisummit/images/lobby.mp4",
  //     route: "",
  //     href:"",
  //   blinkPosition: { left: "83vw", top: "30vh" },
  //   editPosition: { left: "85.8vw", top: "0vh" },
  // },
  // {
  //   id: 5,
  //   img: "https://s3.ap-south-1.amazonaws.com/img.nularksolutions.com/Hubplus-Afrisummit/images/sampleImg1.jpg",
  //   pdf: "",
  //   videoSrc: "",
  //   route: "",
  //   href:"",
  //   blinkPosition: { left: "14.4vw", top: "53vh" },
  //   editPosition: { left: "15vw", top: "35.5vh" },
  // },
  {
    id: 6,
    img: "",
    pdf: "https://virtualpf.s3.ap-south-1.amazonaws.com/afrisummit/lobby/Popup+D1.pdf",
    videoSrc: "",
     route: "",
     href:"",
    blinkPosition: { left: "26.4vw", top: "45.5vh" },
    editPosition: { left: "33vw", top: "35vh" },
  },
  // {
  //   id: 7,
  //   img: "",
  //   pdf: "",
  //   videoSrc: "",
  //   route: "",
  //   href:"",
  //   blinkPosition: { left: "73.4vw", top: "45.5vh" },
  //   editPosition: { left: "79.5vw", top: "36vh" },
  // },
  // {
  //   id: 8,
  //   img: "",
  //   pdf: "",
  //   videoSrc: "",
  //   route: "",
  //   href:"",
  //   blinkPosition: { left: "85.5vw", top: "53.5vh" },
  //   editPosition: { left: "86.5vw", top: "36vh" },
  // },
  {
    id: 9,
    img: "",
    pdf: "",
    videoSrc: "",
    href:"",
    blinkPosition: { left: "28vw", top: "63.5vh" },
    route: "/auditorium",
  },
  // {
  //   id: 10,
  //   route: "/auditorium",
  //   blinkPosition: { left: "50vw", top: "63.5vh" },
  // },
  {
    id: 11,
    img: "",
    pdf: "",
    videoSrc: "",
    href:"",
    route: "/auditorium",
    blinkPosition: { left: "72vw", top: "63.5vh" },
  },
  {
    id: 12,
    img: "",
    pdf: "",
    videoSrc: "",
    route:"",
    href:"https://pramagcc.com/qc/",
    blinkPosition: { left: "81.5vw", top: "79.5vh" },
    editPosition: { left: "82vw", top: "63vh" },
  },
  {
    id: 13,
    img: "",
    pdf: "",
    videoSrc: "https://www.youtube.com/embed/VvjUN3y2hus",
    route:"",
    href:"",
    blinkPosition: { left: "89vw", top: "85.5vh" },
    editPosition: { left: "89.7vw", top: "64vh" },
  },
  {
    id: 14,
    img: "",
    pdf: "",
    videoSrc: "",
    route:"",
    href:"https://www.pramagcc.com/ectd/",
    blinkPosition: { left: "96vw", top: "94vh" },
    editPosition: { left: "97vw", top: "73vh" },
  },
  {
    id: 15,
    img: "",
    pdf: "",
    videoSrc: "",
    route:"",
    href:"https://www.pramagcc.com/MTBT/",
    blinkPosition: { left: "17.3vw", top: "80vh" },
    editPosition: { left: "18vw", top: "62.5vh" },
  },
  {
    id: 16,
    img: "",
    pdf: "",
    videoSrc: "",
    route:"",
    href:"https://www.pramagcc.com/pv/book/",
    blinkPosition: { left: "11.5vw", top: "87.5vh" },
    editPosition: { left: "12.5vw", top: "67vh" },
  },
  {
    id: 17,
    img: "",
    pdf: "",
    videoSrc: "",
    href: "https://www.pramagcc.com/ra/gcc-regulatory-affairs-pharma-summit-agenda/",
    blinkPosition: { left: "5vw", top: "93vh" },
    editPosition: { left: "6vw", top: "72vh" },
  },
];

export default function LobbyItems() {
  const isAdmin = window.sessionStorage.getItem("adminAccess");
  const [isOpen, setIsOpen] = React.useState(false);
  const [selectedItem, setSelectedItem] = React.useState("");
  const [option, setOption] = React.useState("");
  const { role, accessLevel } = useSelector(adminState);
  const { register, handleSubmit, getValues } = useForm();
  const handleSelectChange = (e, value) => {
    setOption(value);
  };
  const handleClose = () => {
    setIsOpen(false);
  };
  React.useEffect(() => {
    if (
      selectedItem.type &&
      Object.values(selectedItem.type).some((value) => value !== "")
    ) {
      const nonEmptyType = Object.keys(selectedItem.type).find(
        (key) => selectedItem.type[key] !== ""
      );
      if (nonEmptyType) {
        setOption(nonEmptyType);
      }
    }
  }, [selectedItem]);

  const onSubmit = (data) => {
    const selectedTypeKey = getValues("selectedType");
    const selectedTypeValue = data.posterURL;

    const selectedType = {
      ...selectedItem.type,
      [selectedTypeKey]: selectedTypeValue,
    };
    const finalData = selectedType;
  };
  return (
    <>
      {role === "admin" ? (
        <Modal
          aria-labelledby="modal-title"
          aria-describedby="modal-desc"
          open={isOpen}
          onClose={handleClose}
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <Sheet
            variant="outlined"
            sx={{
              maxWidth: 500,
              borderRadius: "md",
              p: 3,
              boxShadow: "lg",
            }}
          >
            <ModalClose
              variant="outlined"
              sx={{
                top: "calc(-1/4 * var(--IconButton-size))",
                right: "calc(-1/4 * var(--IconButton-size))",
                boxShadow: "0 2px 12px 0 rgba(0 0 0 / 0.2)",
                borderRadius: "50%",
                bgcolor: "background.surface",
              }}
            />
            <Typography
              variant="h6"
              component="h6"
              className="text-center mb-2"
            >
              Posters
            </Typography>
            <Box
              component="form"
              sx={{ display: "flex", flexDirection: "column", gap: 2 }}
              onSubmit={handleSubmit(onSubmit)}
            >
              {selectedItem?.type && (
                <FormControl>
                  <FormLabel id="demo-simple-select-label">
                    Select Type
                  </FormLabel>
                  <Select
                    defaultValue={option}
                    {...register("selectedType")}
                    label="Select Type"
                    onChange={handleSelectChange}
                  >
                    {Object.keys(selectedItem.type).map((key) => (
                      <Option key={key} value={key}>
                        {key}
                      </Option>
                    ))}
                  </Select>
                </FormControl>
              )}
              <Input size="sm" type="file" {...register("posterURL")} />
              <Button type="submit" size="small" variant="contained">
                Submit
              </Button>
            </Box>
          </Sheet>
        </Modal>
      ) : null}
      {data.map((item, i) => (
        <React.Fragment key={i}>
          <BlinkButton
            sx={item.blinkPosition}
            imgSrc={item.img}
            pdf={item.pdf}
            videoSrc={item.videoSrc}
            route={item.route}
            href={item.href}
          />
          {role === "admin" ? (
            item.editPosition ? (
              <IconButton
                size="small"
                sx={[
                  item.editPosition,
                  {
                    position: "absolute",
                    zIndex: 10,
                    backgroundColor: "#f9a825 !important",
                    color: "#000 !important",
                    textTransform: "capitalize",
                  },
                ]}
                onClick={() => {
                  setSelectedItem(item);
                  setIsOpen(true);
                }}
              >
                <EditIcon sx={{ fontSize: "1rem !important" }} />
              </IconButton>
            ) : null
          ) : null}
        </React.Fragment>
      ))}
    </>
  );
}
