import React from "react";
import { useDispatch, useSelector } from "react-redux";
import Entrance from "../Utils/Entrance";
import {
  Backdrop,
  Box,
  CircularProgress,
  IconButton,
  Tooltip,
} from "@mui/material";
import { Dropdown, MenuButton, Menu, MenuItem } from "@mui/joy";
import LogoutIcon from "@mui/icons-material/Logout";
import EventIcon from "@mui/icons-material/Event";
import RateReviewIcon from "@mui/icons-material/RateReview";
import ConnectWithoutContactIcon from "@mui/icons-material/ConnectWithoutContact";
import HomeIcon from "@mui/icons-material/Home";
import QuizIcon from "@mui/icons-material/Quiz";
import {
  authState,
  logoutAsync,
  setAuditoriumEntrance,
  setDrawerPdf,
  setOpenDrawer,
  setShowNavbar,
} from "../Auth/reduxSlice";
import { useNavigate } from "react-router-dom";
import FormModal from "../Utils/FormModal";
import { Button } from "@mui/joy";
import { adminState } from "../AdminRole/reduxSlice";
import AuditoriumEntrance from "./Form/AuditoriumEntrance";
import AuditoriumForm from "./Form/AuditoriumForm";
import { auditoriumState } from "./reduxSlice";
import SideDrawerProps from "../Utils/SideDrawerProps";
import LanguageIcon from "@mui/icons-material/Language";

export default function Auditorium() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { role, accessLevel } = useSelector(adminState);

  const { openDrawer, drawer } = useSelector(authState);
  const { collection } = useSelector(auditoriumState);
  const [open, setOpen] = React.useState(false);
  const [drawerprops, steDrawerProps] = React.useState({
    pollLink: "",
    qnaLink: "",
    groupChatLink: "",
    feedbackLink: "",
    showLink: "",
  });
  const [selectedIndex, setSelectedIndex] = React.useState(0);

  const createHandleClose = (index) => () => {
    if (typeof index === "number") {
      setSelectedIndex(index);
    }
  };
  const handleClick = () => {
    setOpen(true);
  };
  const handleClose = () => setOpen(false);
  const [admin, setAdmin] = React.useState({
    entranceVideo:
      "https://virtualpf.s3.ap-south-1.amazonaws.com/afrisummit/entrance/full+vedio.mp4",
    bgImg:
      "https://virtualpf.s3.ap-south-1.amazonaws.com/afrisummit/auditorium/Auditorium.gif",
    auditoriumYoutubeVideo: "https://www.youtube.com/embed/okwZrzF_L4s",
    poster:
      "https://virtualpf.s3.ap-south-1.amazonaws.com/afrisummit/lobby/thumbnail.JPG",
    feedback: "",
    poll: "",
    groupChat: "",
    QA: "",
  });

  const { auditoriumEntrance, loading } = useSelector(authState);
  const handleEntrance = () => {
    dispatch(setAuditoriumEntrance({ value: true }));
  };

  React.useEffect(() => {
    if (!auditoriumEntrance) {
      dispatch(setShowNavbar({ value: false }));
    } else {
      dispatch(setShowNavbar({ value: true }));
    }
  }, [auditoriumEntrance]);

  return (
    <>
      <Backdrop
        sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={loading === false ? false : true}
      >
        <CircularProgress color="inherit" />
      </Backdrop>

      {role === "admin" && !auditoriumEntrance ? (
        <FormModal>
          <AuditoriumEntrance />
        </FormModal>
      ) : (
        <FormModal>
          <AuditoriumForm />
        </FormModal>
      )}
      {auditoriumEntrance ? (
        <Box
          className="auditorium"
          sx={{
            background: `url(${
              collection ? collection?.backgroundImage?.Location : admin.bgImg
            })`,
            backgroundRepeat: "no-repeat",
            backgroundSize: "auto 100%",
            backgroundPosition: "center",
            width: "100%",
            height: "100vh",
          }}
        >
          <Box
            sx={{
              display: "flex",
              justifyContent: "center",
              paddingTop: "17vh",
            }}
          >
            <Box
              sx={{
                position: "relative",
                width: "40vw",
                // maxWidth: "42vw",
                aspectRatio: "16/9",
              }}
            >
              <iframe
                title="auditorium"
                src={
                  collection
                    ? selectedIndex === 0
                      ? collection.showLink
                      : collection.frenchShowLink
                    : admin.auditoriumYoutubeVideo
                }
                allowFullScreen
                width="100%"
                height="100%"
                id="myframe"
                style={{ aspectRatio: "16/9" }}
              />
            </Box>
            <Box
              sx={{
                position: "absolute",
                left: "2vw",
                top: "2vh",
                display: "flex",
                flexDirection: "row",
                columnGap: 1,
              }}
            >
              {collection ? (
                <>
                  <Tooltip title="Back">
                    <IconButton
                      sx={{
                        border: 2,
                        borderColor: "#0D89AF",
                        borderRadius: 2,
                        p: "6px",
                      }}
                      onClick={() => navigate("/")}
                    >
                      <HomeIcon sx={{ color: "#B4AE36" }} />
                    </IconButton>
                  </Tooltip>
                  <Tooltip title="FeedBack Form">
                    <IconButton
                      sx={{
                        border: 2,
                        borderColor: "#0D89AF",
                        borderRadius: 2,
                        p: "6px",
                      }}
                      onClick={() => {
                        steDrawerProps({
                          pollLink: "",
                          qnaLink: "",
                          groupChatLink: "",
                          feedbackLink: collection?.feedbackLink,
                          showLink: "",
                          faqLink: "",
                          agendaLink: "",
                        });
                        handleClick();
                      }}
                    >
                      <RateReviewIcon sx={{ color: "#B4AE36" }} />
                    </IconButton>
                  </Tooltip>
                  <Tooltip title="Chat & Poll">
                    <IconButton
                      sx={{
                        border: 2,
                        borderColor: "#0D89AF",
                        borderRadius: 2,
                        p: "6px",
                      }}
                      onClick={() => {
                        steDrawerProps({
                          pollLink: "",
                          qnaLink: "",
                          groupChatLink: collection?.groupChatLink,
                          feedbackLink: "",
                          showLink: "",
                          faqLink: "",
                          agendaLink: "",
                        });
                        handleClick();
                      }}
                    >
                      <ConnectWithoutContactIcon sx={{ color: "#B4AE36" }} />
                    </IconButton>
                  </Tooltip>
                  <Tooltip title="Agenda">
                    <IconButton
                      sx={{
                        border: 2,
                        borderColor: "#0D89AF",
                        borderRadius: 2,
                        p: "6px",
                      }}
                      onClick={() => {
                        steDrawerProps({
                          pollLink: "",
                          qnaLink: "",
                          groupChatLink: "",
                          feedbackLink: "",
                          showLink: "",
                          faqLink: "",
                          agendaLink:
                            "https://virtualpf.s3.ap-south-1.amazonaws.com/afrisummit/auditorium/PV+Training+AfriSummit+2023+Agenda+(2)+(1).pdf",
                        });
                        handleClick();
                      }}
                    >
                      <EventIcon sx={{ color: "#B4AE36" }} />
                    </IconButton>
                  </Tooltip>
                  <Tooltip title="FAQ">
                    <IconButton
                      sx={{
                        border: 2,
                        borderColor: "#0D89AF",
                        borderRadius: 2,
                        p: "6px",
                      }}
                      onClick={() => {
                        steDrawerProps({
                          pollLink: "",
                          qnaLink: "",
                          groupChatLink: "",
                          feedbackLink: "",
                          showLink: "",
                          faqLink:
                            "https://virtualpf.s3.ap-south-1.amazonaws.com/afrisummit/auditorium/AfriSummit+2023+-+Frequently+asked+Questions.pdf",
                        });
                        handleClick();
                      }}
                    >
                      <QuizIcon sx={{ color: "#B4AE36" }} />
                    </IconButton>
                  </Tooltip>
                  <Tooltip title="Logout">
                    <IconButton
                      sx={{
                        border: 2,
                        borderColor: "#0D89AF",
                        borderRadius: 2,
                        p: "6px",
                      }}
                      onClick={() => {
                        dispatch(logoutAsync({ accessLevel, navigate }));
                      }}
                    >
                      <LogoutIcon sx={{ color: "#B4AE36" }} />
                    </IconButton>
                  </Tooltip>
                </>
              ) : null}
            </Box>
            <Box
              sx={{
                position: "absolute",
                left: "2vw",
                top: "auto",
                bottom: "2vh",
                display: "flex",
                flexDirection: "row",
                columnGap: 1,
              }}
            >
              {collection ? (
                <Dropdown>
                  <MenuButton
                  color='neutral'
                  variant="solid"
                    sx={{
                      border: 2,
                      borderColor: "#0D89AF",
                      borderRadius: 5,
                      backgroundColor: "#0D89AF",
                      color:"#fff",
                      p: "6px 7px",
                    }}
                    startDecorator={<LanguageIcon sx={{ color: "#fff" }} />}
                  >
                    Language
                  </MenuButton>
                  <Menu>
                    <MenuItem
                      {...(selectedIndex === 0 && {
                        selected: true,
                        variant: "soft",
                      })}
                      onClick={createHandleClose(0)}
                    >
                      English
                    </MenuItem>
                    <MenuItem
                      selected={selectedIndex === 1}
                      onClick={createHandleClose(1)}
                    >
                      French
                    </MenuItem>
                  </Menu>
                </Dropdown>
              ) : null}
            </Box>
          </Box>
          <SideDrawerProps
            props={drawerprops}
            open={open}
            handleClose={handleClose}
          />
        </Box>
      ) : (
        <Entrance
          key={admin.entranceVideo}
          handleEntrance={handleEntrance}
          vidSrc={
            "https://virtualpf.s3.ap-south-1.amazonaws.com/afrisummit/entrance/full+vedio.mp4"
          }
          welcomeMsg={"Welcome to Pharmareg Afrisummit"}
          autoPlay={role === "admin" ? false : true}
          poster={admin.poster}
        />
      )}
    </>
  );
}
