import * as React from "react";
import BottomNavigation from "@mui/material/BottomNavigation";
import BottomNavigationAction from "@mui/material/BottomNavigationAction";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import HomeIcon from "@mui/icons-material/Home";
import VillaIcon from "@mui/icons-material/Villa";
import PhotoLibraryIcon from "@mui/icons-material/PhotoLibrary";
import EventAvailableIcon from "@mui/icons-material/EventAvailable";
import QuizIcon from "@mui/icons-material/Quiz";
import LogoutIcon from "@mui/icons-material/Logout";
import Diversity3Icon from "@mui/icons-material/Diversity3";
import StadiumIcon from "@mui/icons-material/Stadium";
import { lobbyState } from "../Lobby/reducSlice";
import { logoutAsync, setDrawerPdf, setOpenDrawer } from "../Auth/reduxSlice";
import {adminState} from "../AdminRole/reduxSlice"

export default function BottomNav() {
  const { navItems } = useSelector(lobbyState);
  const {accessLevel} = useSelector(adminState)
  const dispatch = useDispatch();
  const tab = window.sessionStorage.getItem("tab");
  const [value, setValue] = React.useState(tab ? tab : "Lobby");
  window.sessionStorage.setItem("tab", value);
  const navigate = useNavigate();
  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const icons = [
    <HomeIcon />,
    <VillaIcon />,
    // <Diversity3Icon />,
    // <StadiumIcon />,
    // <PhotoLibraryIcon />,
    <EventAvailableIcon />,
    <QuizIcon />,
    <LogoutIcon />,
  ];

  return (
    <BottomNavigation
      sx={{
        width: "auto",
        borderRadius: 5,
        px: 2,
        bgcolor: "rgba(255,255,255,0.6)",
        backdropFilter: "blur(8px)",
        WebkitBackdropFilter: "blur(8px)",
        "& .Mui-selected": {
          color: "#0009",
        },
        "& .Mui-selected .MuiSvgIcon-root": {
          color: "#0009",
        },
        "& .MuiButtonBase-root": {
          color: "#0009",
        },
      }}
      value={value}
      onChange={handleChange}
      showLabels
    >
      {navItems.map(({ name, route, show }, i) => (
        <BottomNavigationAction
          sx={{ display: show ? "auto" : "none" }}
          key={i}
          label={name}
          value={name}
          icon={icons[i]}
          onClick={() => {
            window.sessionStorage.setItem("tab", name);
            navigate(route);
            if(name === "Logout"){
              dispatch(logoutAsync({navigate}))
            }else if(name === "FAQ"){
              dispatch(setOpenDrawer({value:true}))
              dispatch(setDrawerPdf({
                pdf: "https://virtualpf.s3.ap-south-1.amazonaws.com/afrisummit/auditorium/AfriSummit+2023+-+Frequently+asked+Questions.pdf",
                chat: "",
                vidChat: "",
                list: "",
              }))
            }else if(name === "Agenda"){
              dispatch(setOpenDrawer({value:true}))
              dispatch(setDrawerPdf({
                pdf: "https://virtualpf.s3.ap-south-1.amazonaws.com/afrisummit/auditorium/PV+Training+AfriSummit+2023+Agenda+(2)+(1).pdf",
                chat: "",
                vidChat: "",
                list: "",
              }))
            }
          }}
        />
      ))}
    </BottomNavigation>
  );
}
