import * as React from "react";
import { Box, FormControl, FormLabel, Input, Button } from "@mui/joy";
import { Typography } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { useForm } from "react-hook-form";
import { setFormModal } from "../../Auth/reduxSlice";
import { adminState } from "../../AdminRole/reduxSlice";
import {
  auditoriumPostAsync,
  auditoriumState,
  auditoriumUpdateAsync,
} from "../reduxSlice";
export default function AuditoriumForm() {
  const dispatch = useDispatch();
  const { register, handleSubmit, reset } = useForm();
  const { accessLevel } = useSelector(adminState);
  const { collection } = useSelector(auditoriumState);
  const [files, setFiles] = React.useState("");
  const [preview, setPreview] = React.useState("");
  const onSelect = (e) => {
    setFiles(e.target.files[0]);
    setPreview(URL.createObjectURL(e.target.files[0]));
  };
  const handlePost = (data) => {
    let keys = Object.keys(data);
    let value = Object.values(data);
    var formdata = new FormData();
    formdata.append("accessLevel", accessLevel);
    for (let i = 0; i < keys.length; i++) {
      formdata.append(keys[i], value[i]);
    }
    formdata.append("", files);
    if (collection) {
      dispatch(auditoriumUpdateAsync({ formdata }));
    } else {
      dispatch(auditoriumPostAsync({ formdata }));
    }
    reset();
    dispatch(setFormModal({ value: false }));
  };
  return (
    <Box
      component="form"
      sx={{ display: "flex", flexDirection: "column", gap: 1 }}
      onSubmit={handleSubmit(handlePost)}
    >
      <Typography variant="h6" component="h6" className="text-center">
        Auditorium
      </Typography>
      <FormControl size="sm">
        <FormLabel>Background Image</FormLabel>
        <Input
          type="file"
          slotProps={{
            input: { accept: "image/*" },
          }}
          onChange={onSelect}
        />
      </FormControl>
      <FormControl size="sm">
        <FormLabel>Auditorium Show Link</FormLabel>
        <Input
          type="text"
          placeholder="Embedded youtube link"
          {...register("showLink")}
          defaultValue={collection ? collection?.showLink : null}
        />
      </FormControl>
      <FormControl size="sm">
        <FormLabel>Auditorium French Show Link</FormLabel>
        <Input
          type="text"
          placeholder="Embedded youtube link"
          {...register("frenchShowLink")}
          defaultValue={collection ? collection?.frenchShowLink : null}
        />
      </FormControl>
      <FormControl size="sm">
        <FormLabel>Feedback Embedded link</FormLabel>
        <Input
          type="text"
          {...register("feedbackLink")}
          defaultValue={collection ? collection?.feedbackLink : null}
        />
      </FormControl>
      <FormControl size="sm">
        <FormLabel>Poll Embedded link</FormLabel>
        <Input
          type="text"
          {...register("pollLink")}
          defaultValue={collection ? collection?.pollLink : null}
        />
      </FormControl>
      <FormControl size="sm">
        <FormLabel>GroupChat Embedded link</FormLabel>
        <Input
          type="text"
          {...register("groupChatLink")}
          defaultValue={collection ? collection?.groupChatLink : null}
        />
      </FormControl>
      <FormControl size="sm">
        <FormLabel>Q&A Embedded link</FormLabel>
        <Input
          type="text"
          {...register("qnaLink")}
          defaultValue={collection ? collection?.qnaLink : null}
        />
      </FormControl>
      <Button size="sm" variant="solid" type="submit">
        Submit
      </Button>
    </Box>
  );
}
