import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import { db_database, functions } from "../../../firebase/config";
import { httpsCallable } from "firebase/functions";
import { Timestamp } from "firebase/firestore";
import { child, push, ref, set, update } from "firebase/database";
const initialState = {
  loading: false,
  loadingButton: false,
  signup: false,
  rememberMe: false,
  location: "",
  ipAddress: "",
  isAuthenticated: false,
  openDrawer: false,
  drawer: {
    pdf: "",
    chat: "",
    vidChat: "",
    list: "",
    feedbackLink: "",
  },
  modal: {
    state: false,
    imageSrc: "",
    videoSrc: "",
  },
  lobbyEntrance: false,
  auditoriumEntrance: true,
  formModal: false,
  showNavbar: false,
  counterOver: false,
  collection: "",
};

export const loginAsync = createAsyncThunk(
  "auth/loginAPI",
  async (
    { email, accessLevel, navigate },
    { dispatch, getState, rejectWithValue }
  ) => {
    try {
      const func = httpsCallable(functions, "userLogin");
      const response = await func({ email, accessLevel });
      if (response.data.uid) {
        navigate("/");
        window.localStorage.setItem("email", email);
        window.sessionStorage.setItem("token", response.data.uid);
      } else if (response.data.message) {
        alert(response.data.message);
      }
      return response;
    } catch (error) {
      if (error.name === "FirebaseError") {
        console.log(error.code, error.message);
      }
      return rejectWithValue(error);
    }
  }
);
export const sessionUpdateAsync = createAsyncThunk(
  "auth/sessionUpdateAPI",
  async (
    { accessLevel },
    { dispatch, getState, rejectWithValue }
  ) => {
    try {
      const email =  window.localStorage.getItem("email");
      const func = httpsCallable(functions, "confirmatingSession");
      const response = await func({ email, accessLevel });
      return response;
    } catch (error) {
      if (error.name === "FirebaseError") {
        console.log(error.code, error.message);
      }
      return rejectWithValue(error);
    }
  }
);

export const logoutAsync = createAsyncThunk(
  "auth/userLogout",
  async (
    { accessLevel, navigate },
    { dispatch, getState, rejectWithValue }
  ) => {
    try {
      const email = window.localStorage.getItem("email");
      const func = httpsCallable(functions, "userLogout");
      const response = await func({ email, accessLevel });
      if (response.data) {
        navigate("/");
        window.localStorage.clear();
        window.sessionStorage.clear();
      }
      return response;
    } catch (error) {
      if (error.name === "FirebaseError") {
        console.log(error.code, error.message);
      }
      return rejectWithValue(error);
    }
  }
);

export const getPagesAsync = createAsyncThunk(
  "auth/getPagesAPI",
  async (
    { navigate, accessLevel },
    { dispatch, getState, rejectWithValue }
  ) => {
    try {
      const func = httpsCallable(functions, "getAuthPage");
      const response = await func({ accessLevel });
      return response;
    } catch (error) {
      if (error.name === "FirebaseError") {
        console.log(error.code, error.message);
      }
      return rejectWithValue(error);
    }
  }
);

export const authWithPdfAsync = createAsyncThunk(
  "auth/authWithPdf",
  async ({ formdata }, { dispatch, getState, rejectWithValue }) => {
    try {
      const api =
        "https://asia-south1-virtual-premium-platform.cloudfunctions.net/authWithPdf";
      // "http://localhost:5001/virtual-premium-platform/asia-south1/authWithPdf";
      const response = await axios.post(api, formdata);
      return response;
    } catch (error) {
      if (error.name === "FirebaseError") {
        console.log(error.code, error.message);
      }
      return rejectWithValue(error);
    }
  }
);

export const updateAuthWithPdfAsync = createAsyncThunk(
  "auth/updateAuthWithPdf",
  async ({ formdata }, { dispatch, getState, rejectWithValue }) => {
    try {
      const api =
        "https://asia-south1-virtual-premium-platform.cloudfunctions.net/updateAuthWithPdf";
      // "http://localhost:5001/virtual-premium-platform/asia-south1/updateAuthWithPdf";
      const response = await axios.post(api, formdata);
      return response;
    } catch (error) {
      if (error.name === "FirebaseError") {
        console.log(error.code, error.message);
      }
      return rejectWithValue(error);
    }
  }
);

export const updateAuthWithTextAsync = createAsyncThunk(
  "auth/updateAuthWithText",
  async ({ formdata }, { dispatch, getState, rejectWithValue }) => {
    try {
      const api =
        "https://asia-south1-virtual-premium-platform.cloudfunctions.net/updateAuthWithText";
      // "http://localhost:5001/virtual-premium-platform/asia-south1/updateAuthWithText";
      const response = await axios.post(api, formdata);
      return response;
    } catch (error) {
      if (error.name === "FirebaseError") {
        console.log(error.code, error.message);
      }
      return rejectWithValue(error);
    }
  }
);

export const authWithTextAsync = createAsyncThunk(
  "auth/authWithText",
  async ({ formdata }, { dispatch, getState, rejectWithValue }) => {
    try {
      const api =
        "https://asia-south1-virtual-premium-platform.cloudfunctions.net/authWithPdf";
      // "http://localhost:5001/virtual-premium-platform/asia-south1/authWithText";
      const response = await axios.post(api, formdata);
      return response;
    } catch (error) {
      if (error.name === "FirebaseError") {
        console.log(error.code, error.message);
      }
      return rejectWithValue(error);
    }
  }
);

export const authSlice = createSlice({
  name: "auth",
  initialState,
  reducers: {
    setIsAuthenticated: (state, action) => {
      state.isAuthenticated = action.payload.value;
    },
    setOpenDrawer: (state, action) => {
      state.openDrawer = action.payload.value;
    },
    setDrawerPdf: (state, action) => {
      state.drawer = action.payload;
    },
    setModal: (state, action) => {
      state.modal = action.payload.modal;
    },
    setLobbyEntrance: (state, action) => {
      state.lobbyEntrance = action.payload.value;
    },
    setAuditoriumEntrance: (state, action) => {
      state.auditoriumEntrance = action.payload.value;
    },
    setFormModal: (state, action) => {
      state.formModal = action.payload.value;
    },
    setShowNavbar: (state, action) => {
      state.showNavbar = action.payload.value;
    },
    setCounterOver: (state, action) => {
      state.counterOver = action.payload.value;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(loginAsync.pending, (state, action) => {
        state.loadingButton = true;
      })
      .addCase(loginAsync.fulfilled, (state, action) => {
        state.loadingButton = false;
        state.isAuthenticated = true;
      })
      .addCase(loginAsync.rejected, (state, action) => {
        state.loadingButton = false;
      })
      .addCase(logoutAsync.pending, (state, action) => {
        state.loading = true;
      })
      .addCase(logoutAsync.fulfilled, (state, action) => {
        state.loading = false;
        state.isAuthenticated = false;
      })
      .addCase(logoutAsync.rejected, (state, action) => {
        state.loading = false;
      })
      .addCase(authWithPdfAsync.pending, (state, action) => {
        state.loading = true;
      })
      .addCase(authWithPdfAsync.fulfilled, (state, action) => {
        state.loading = false;
        state.collection = action.payload.data;
      })
      .addCase(authWithPdfAsync.rejected, (state, action) => {
        state.loading = false;
      })
      .addCase(updateAuthWithPdfAsync.pending, (state, action) => {
        state.loading = true;
      })
      .addCase(updateAuthWithPdfAsync.fulfilled, (state, action) => {
        state.loading = false;
        state.collection = action.payload.data;
      })
      .addCase(updateAuthWithPdfAsync.rejected, (state, action) => {
        state.loading = false;
      })
      .addCase(authWithTextAsync.pending, (state, action) => {
        state.loading = true;
      })
      .addCase(authWithTextAsync.fulfilled, (state, action) => {
        state.loading = false;
        state.collection = action.payload.data;
      })
      .addCase(authWithTextAsync.rejected, (state, action) => {
        state.loading = false;
      })
      .addCase(updateAuthWithTextAsync.pending, (state, action) => {
        state.loading = true;
      })
      .addCase(updateAuthWithTextAsync.fulfilled, (state, action) => {
        state.loading = false;
        state.collection = action.payload.data;
      })
      .addCase(updateAuthWithTextAsync.rejected, (state, action) => {
        state.loading = false;
      })
      .addCase(getPagesAsync.pending, (state, action) => {
        state.loading = true;
      })
      .addCase(getPagesAsync.fulfilled, (state, action) => {
        state.loading = false;
        state.collection = action.payload.data;
      })
      .addCase(getPagesAsync.rejected, (state, action) => {
        state.loading = false;
      });
  },
});

export const {
  setIsAuthenticated,
  setOpenDrawer,
  setDrawerItem,
  setModal,
  setDrawerPdf,
  setLobbyEntrance,
  setAuditoriumEntrance,
  setFormModal,
  setShowNavbar,
  setCounterOver,
} = authSlice.actions;
export const authState = (state) => state.auth;
export default authSlice.reducer;
