import React from "react";
import { Box, Typography } from "@mui/material";
import QuestionAnswerIcon from "@mui/icons-material/QuestionAnswer";
import {
  authState,
  setDrawerPdf,
  setFormModal,
  setLobbyEntrance,
  setOpenDrawer,
  setShowNavbar,
} from "../Auth/reduxSlice";
import { useDispatch, useSelector } from "react-redux";
import CustomModal from "./components/CustomModal";
import FormModal from "../Utils/FormModal";
import LobbyItems from "./LobbyItems";
import { useForm } from "react-hook-form";
import {
  Button,
  Checkbox,
  Divider,
  FormControl,
  FormLabel,
  IconButton,
  Input,
  Tooltip,
} from "@mui/joy";
import { lobbyState, setNavItems } from "./reducSlice";
import Entrance from "../Utils/Entrance";
import { adminState } from "../AdminRole/reduxSlice";
import { auditoriumState } from "../Auditorium/reduxSlice";
import SideDrawerProps from "../Utils/SideDrawerProps";

export default function Lobby() {
  const dispatch = useDispatch();
  const { navItems } = useSelector(lobbyState);
  const { modal, lobbyEntrance } = useSelector(authState);
  const { role, accessLevel } = useSelector(adminState);
  const { collection } = useSelector(auditoriumState);
  const [open, setOpen] = React.useState(false);
  const [drawerprops, steDrawerProps] = React.useState({
    pollLink: "",
    qnaLink: "",
    groupChatLink: "",
    feedbackLink: "",
    showLink: "",
  });
  const handleClick = () => {
    setOpen(true);
  };
  const handleClose = () => setOpen(false);
  const { register: registerEntrance, handleSubmit: handleSubmitEntrance } =
    useForm();
  const { register: registerLobby, handleSubmit: handleSubmitLobby } =
    useForm();
  const [admin, setAdmin] = React.useState({
    welcomeMsg: "",
    entranceVideo:
      "https://virtualpf.s3.ap-south-1.amazonaws.com/afrisummit/entrance/full+vedio.mp4",
    bgImg:
      "https://virtualpf.s3.ap-south-1.amazonaws.com/afrisummit/lobby/Lobby-Afrri-summit-1920x1080.png",
    lobbyVideo:
      "https://virtualpf.s3.ap-south-1.amazonaws.com/afrisummit/lobby/PV+AfriSummit+Video++-++2023.mp4",
    poster:
      "https://virtualpf.s3.ap-south-1.amazonaws.com/afrisummit/lobby/thumbnail.JPG",
    tvposter:
      "https://virtualpf.s3.ap-south-1.amazonaws.com/afrisummit/lobby/tvthumbnail.png",
  });
  const handleEntrance = () => {
    dispatch(setLobbyEntrance({ value: true }));
  };

  const handleAdminChangesEntrance = (data) => {
    console.log("Entrance", data);
    dispatch(setFormModal({ value: false }));
  };

  const handleAdminChangesLobby = (data) => {
    dispatch(setFormModal({ value: false }));
  };
  const handleChat = () => {
    dispatch(
      setDrawerPdf({
        pdf: "",
        chat: collection?.groupChatLink,
        vidChat: "",
        list: "",
      })
    );
    dispatch(setOpenDrawer({ value: true }));
  };
  React.useEffect(() => {
    if (!lobbyEntrance) {
      dispatch(setShowNavbar({ value: false }));
    } else {
      dispatch(setShowNavbar({ value: true }));
    }
  }, [lobbyEntrance]);
  return (
    <>
      {role === "admin" ? (
        lobbyEntrance ? (
          <FormModal>
            <Box
              component="form"
              sx={{ display: "flex", flexDirection: "column", gap: 1 }}
              onSubmit={handleSubmitLobby(handleAdminChangesLobby)}
            >
              <Typography variant="h6" component="h6" className="text-center">
                Lobby
              </Typography>
              <FormControl size="sm">
                <FormLabel>Background Image size : 1920 x 1080</FormLabel>
                <Input
                  type="file"
                  accept="images/*"
                  {...registerLobby("bgImg")}
                />
              </FormControl>
              <FormControl size="sm">
                <FormLabel>Lobby Center Video</FormLabel>
                <Input
                  type="file"
                  accept="video/mp4"
                  {...registerLobby("lobbyVideo")}
                />
              </FormControl>
              <FormControl size="sm">
                <FormLabel>Agenda Pdf</FormLabel>
                <Input
                  type="file"
                  accept="application/pdf"
                  {...registerLobby("agendaPdf")}
                />
              </FormControl>
              <FormControl size="sm">
                <FormLabel>FAQ Pdf</FormLabel>
                <Input
                  type="file"
                  accept="application/pdf"
                  {...registerLobby("faqPdf")}
                />
              </FormControl>
              <Box>
                <FormLabel sx={{ mb: 1 }}>Navbar Items</FormLabel>
                {navItems?.map((item, i) => (
                  <React.Fragment key={i}>
                    <Checkbox
                      size="sm"
                      {...registerLobby(`navItems[${i}].show`)}
                      label={item.name}
                      checked={item.show}
                      sx={{ mr: 2 }}
                      onChange={(e) => {
                        dispatch(
                          setNavItems({ index: i, show: e.target.checked })
                        );
                      }}
                    />
                    <input
                      type="hidden"
                      {...registerLobby(`navItems[${i}].name`)}
                      value={item.name}
                    />
                  </React.Fragment>
                ))}
              </Box>

              <Button size="sm" type="submit" variant="solid">
                Submit
              </Button>
            </Box>
          </FormModal>
        ) : (
          <FormModal>
            <Box
              component="form"
              sx={{ display: "flex", flexDirection: "column", gap: 2 }}
              onSubmit={handleSubmitEntrance(handleAdminChangesEntrance)}
            >
              <Typography variant="h6" component="h6" className="text-center">
                Lobby Entrance
              </Typography>
              <Divider />
              <FormControl>
                <FormLabel>Entrance Video</FormLabel>
                <Input
                  size="sm"
                  type="file"
                  accept="video/mp4"
                  {...registerEntrance("entranceVideo")}
                />
              </FormControl>
              <FormControl>
                <FormLabel>Welcome Message</FormLabel>
                <Input
                  size="sm"
                  type="text"
                  {...registerEntrance("welcomeMsg")}
                  placeholder="Welcome message..."
                />
              </FormControl>

              <Button size="sm" type="submit" variant="solid">
                Submit
              </Button>
            </Box>
          </FormModal>
        )
      ) : null}
      <Box
        className="lobby"
        sx={{
          background: `url(${admin.bgImg})`,
          backgroundRepeat: "no-repeat",
          backgroundSize: "100% 100%",
          width: "100%",
          height: "100vh",
        }}
      >
        {!lobbyEntrance ? (
          <Entrance
            key={admin.entranceVideo}
            handleEntrance={handleEntrance}
            vidSrc={admin.entranceVideo}
            welcomeMsg={"Welcome to Pharmacovigilance"}
            autoPlay={role === "admin" ? false : true}
            poster={admin.poster}
          />
        ) : (
          <React.Fragment>
            <Box>
              <Tooltip title="Chat & Poll">
                <IconButton
                  color="neutral"
                  variant="plain "
                  sx={{
                    p: "3px",
                    position: "absolute",
                    top: "75.5vh",
                    right: "39vw",
                  }}
                  onClick={() => {
                    steDrawerProps({
                      pollLink: "",
                      qnaLink: "",
                      groupChatLink: collection?.groupChatLink,
                      feedbackLink: "",
                      showLink: "",
                      faqLink: "",
                      agendaLink: "",
                    });
                    handleClick();
                  }}
                >
                  <QuestionAnswerIcon sx={{ color: "#B4AE36" }} />
                </IconButton>
              </Tooltip>

              <Box
                sx={{
                  position: "absolute",
                  top: "32.5vh",
                  left: "42.3vw",
                  maxWidth: "16.6vw",
                  maxHeight: "19vh",
                  aspectRatio: 16 / 9,
                }}
              >
                <video
                  controls={true}
                  playsInline
                  autoPlay="true"
                  poster={admin.tvposter}
                  width="100%"
                  height="100%"
                >
                  <source src={admin.lobbyVideo} type="video/mp4" />
                </video>
              </Box>

              <LobbyItems />
            </Box>
            <SideDrawerProps
              props={drawerprops}
              open={open}
              handleClose={handleClose}
            />
          </React.Fragment>
        )}
      </Box>
      {modal?.imageSrc || modal?.videoSrc ? <CustomModal /> : null}
    </>
  );
}
